<template>
    <ul class="menu bg-white max-w-xs w-60 rounded-box divide-gray-200 border-gray-200 divide-y border">
        <li v-for="item in list" :key="item.id"  @click="$emit('select', item)">
            <a class="flex" :class="{'active':selected === item.id }"   >
                <span class="icon mr-4">
                    <component class="fill-current text-current " :is="item.icon" />
                </span>
                <span class="text-current" v-text="item.name"/>
            </a>
        </li>
        <!-- <li><a>Item 2</a></li>
        <li><a>Item 3</a></li> -->
    </ul>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },
        selected: {
            default: 0,
            type: [Number, String]
        }
    }
}
</script>

<style>

</style>