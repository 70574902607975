<template>
    <div class="flex flex-col gap-6 justify-between flex-1">
        <Card :showActionBtn="licenseData.licenseDetails.id" title="License Details">
            <template slot="actions">
                <button @click="$emit('deleteLicense')" class="h-7 cursor-pointer w-7 outline-none rounded-full bg-error text-white flex items-center justify-center">
                    <font-awesome-icon icon="trash" class="text-md text-current" />
                </button>
            </template>
            <div v-if="loadingStates.fetchingDetails" class="py-20 flex justify-center items-center bg-white rounded-lg" hideTitle>
                <Loader />
            </div>
            <div v-else class="grid grid-cols-2 gap-x-4 w-full p-4">
                <FormulateInput class="w-full" v-model="licenseData.licenseDetails.name" label="License Name" name="name" placeholder="License Name" type="text" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" 
                @change="handleLicenseName($event.target)"
                />

                <FormulateInput class="w-full" v-model="licenseData.licenseDetails.description" label="License Description" placeholder="Enter Description" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" />

                <div class="flex flex-col gap-1">
                    <label>Validity in Days <span class="text-red-600 text-lg">*</span></label>
                    <div class="flex justify-center items-center h-10 border rounded-md">
                        <div class="bg-primary-300 text-base-content h-full rounded-l-md flex items-center justify-center px-4">Days</div>
                        <input @keypress="isNumber($event)" min=1 max=377 type="text" v-model="licenseData.licenseDetails.validity" class="flex-1 px-4 w-full h-full rounded-r-lg text-md text-gray-700" placeholder="Validity in Days" />
                    </div>
                </div>
            </div>
        </Card>
        <div class="flex items-center justify-end">
            <Button :disabled="loadingStates.submit || !changeMade" @click="handleFormSubmit" type="primary" text="Save" />
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Card from "@shared/components/card";
import Loader from "@shared/loader";
import { isNumber } from "@/plugins/functions";

export default {
    name: "license-info-card",
    components: {
        Button,
        Card,
        Loader,
    },
    props: {
        licenseData: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            changeMade: false,
            firstRednder: true,
        };
    },
    computed: {
        getLicenseData() {
            return this.licenseData;
        }
    },
    async mounted() {},
    watch: {
        getLicenseData:{
            deep: true,
            handler() {
                if (!this.firstRednder && !this.loadingStates.fetchingDetails) {
                    this.changeMade = true
                }
                if (this.firstRednder) {
                    this.firstRednder = false
                }

            }
        }
    },
    methods: {
        isNumber,
        handleFormSubmit(){
            let { name, validity } = this.licenseData.licenseDetails
            if(!name  || validity == '' ) return this.$toast.error('Please Fill all mandatory fields')
            else if(validity < 1 ) return this.$toast.error('Validity should be greater than 0')
            else if(validity > 377 ) return this.$toast.error('Validity should be less than 377')
            this.$emit('handleFormSubmit', { data: this.licenseData.licenseDetails, section:'license-details' })
            this.changeMade = false;
        },
        handleLicenseName(data) {
            const { name, value } = data;
                this.$emit('handleValidate', name, value.trim())
        }
    },
};
</script>

<style lang="scss" scoped></style>
