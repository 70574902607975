import { render, staticRenderFns } from "./license-info-card.vue?vue&type=template&id=29b9aecc&scoped=true"
import script from "./license-info-card.vue?vue&type=script&lang=js"
export * from "./license-info-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29b9aecc",
  null
  
)

export default component.exports