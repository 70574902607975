<template>
    <div class="flex flex-col gap-6 justify-between max-h-full flex-1 overflow-y-auto overflow-x-hidden">
        <Card showActionBtn title="Feature List">
            <div class="flex flex-1 p-4 overflow-y-auto max-h-full h-full">
                <div v-if="loadingStates.fetchingDetails" class="flex flex-1 items-center justify-center py-14">
                    <Loader />
                </div>
                <List v-else :showTitle="false" :listItems="getFeaturedList" :showSelectAll="true" :showSearch="true" @handleSelect="$emit('selectAllFeatures', $event)" @handleFilterResult="handleFilterResult($event)">
                    <template #item="{ item }">
                        <FeatureListItem :item="item" @licensePackEvent="$emit('licensePackEvent', $event)" @toggleEvent="$emit('licenseReadOnlyPermission', $event)" />
                    </template>
                </List>
            </div>
        </Card>
        <div class="flex items-center justify-end">
            <Button :disabled="loadingStates.submit || !changeMade" @click="handleFormSubmit" type="primary" text="Save" />
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Card from "@shared/components/card";
import List from "@shared/components/lists";
import FeatureListItem from "@shared/components/license-pack/feature-list-item";
import Loader from "@shared/loader";

export default {
    name: "feature-list",
    components: {
        Button,
        Card,
        List,
        FeatureListItem,
        Loader,
    },
    props: {
        licenseData: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
        getFeaturedList:{
            type:Array,
            default: () => []
        }
    },
    data() {
        return {
            changeMade: false,
            firstRender: true
        };
    },
    computed: {
        getFeatureList(){
            return this.getFeaturedList
        }
    },
    async mounted() {},
    watch: {
        getFeatureList:{
            deep: true,
            handler() {
                if (!this.firstRender && !this.loadingStates.fetchingDetails) {
                    this.changeMade = true
                }
                this.firstRender = false
            }
        }
    },
    methods: {
        handleFilterResult(event){
            this.$emit('handleFilterResult', {data:event, type:'features'})
        },
        handleFormSubmit(){
            // let assignedFeatures_length = this.getFeaturedList.filter(el => el.checked).length
            // if(assignedFeatures_length < 1 ) return this.$toast.error('Please select atleast one feature')
            this.$emit('handleFormSubmit', { data: this.getFeaturedList, section:'license-features' })
            this.changeMade = false;
        }
    },
};
</script>

<style lang="scss" scoped></style>
